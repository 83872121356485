<template>
  <div class="xinshoucun">
    <img class="banner" src="@/assets/xsc_banner.png" alt="" />
    <div class="body">
      <div class="aside">
        <div class="menu-wrap">
          <el-menu
            background-color="#16A085"
            text-color="#fff"
            active-text-color="#16A085"
            :default-active="activeMenu"
            router
          >
            <el-menu-item index="/xinshoucun/renshiyapan">
              <div slot="title" class="title">
                <span class="iconfont icon-book"></span>
                <span class="text">认识亚盘</span>
                <span class="el-icon-arrow-right"></span>
              </div>
            </el-menu-item>
            <el-menu-item index="/xinshoucun/yapanbianhua">
              <div slot="title" class="title">
                <span class="iconfont icon-change"></span>
                <span class="text">亚盘变化</span>
                <span class="el-icon-arrow-right"></span>
              </div>
            </el-menu-item>
            <el-menu-item index="/xinshoucun/renshioupei">
              <div slot="title" class="title">
                <span class="iconfont icon-share"></span>
                <span class="text">认识欧赔</span>
                <span class="el-icon-arrow-right"></span>
              </div>
            </el-menu-item>
            <el-menu-item index="/xinshoucun/renshidaxiaoqiu">
              <div slot="title" class="title">
                <span class="iconfont icon-football"></span>
                <span class="text">认识大小球</span>
                <span class="el-icon-arrow-right"></span>
              </div>
            </el-menu-item>
          </el-menu>
        </div>
      </div>

      <div class="main">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {},
  computed: {
    activeMenu() {
      const route = this.$route;
      const { path } = route;
      return path;
    }
  },
  methods: {
    handleClick() {}
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/vars.scss";
.banner {
  display: block;
}
.body {
  display: flex;
  padding: 15px;
  background: #fff;
  .aside {
    margin-right: 20px;
    .menu-wrap {
      background: $--color-primary;
      margin-bottom: 15px;

      padding: 5px;
      .el-menu {
        width: 200px;
        border-right: none;
        .el-menu-item {
          .title {
            display: flex;
            .iconfont {
              margin-right: 10px;
            }
            .text {
              flex: 1;
            }
            .el-icon-arrow-right {
              line-height: inherit;
            }
          }
          &:not(:last-child) {
            &::after {
              display: block;
              content: "";
              width: 155px;
              height: 1px;
              background: #ebe8e8;
              opacity: 0.24;
              z-index: 100;
              position: relative;
              top: -2px;
              left: 5px;
            }
          }

          &.is-active {
            background-color: rgba(255, 255, 255, 0.8) !important;
          }
        }
      }
    }
    .weixin {
      height: 78px;
      background: #f6f7f8;
      display: flex;
      align-items: center;
      .icon-weixin {
        font-size: 32px;
        color: #c8c8c8;
        margin-left: 15px;
        margin-right: 15px;
        .content {
          font-size: 16px;
          line-height: 22px;
          color: #584f4f;
        }
      }
    }
  }

  .main {
    flex: 1;
  }
}
</style>
